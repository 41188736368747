<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import ffxivNews from './widgets/ffxivnews';
import ffxivMaintenance from './widgets/maintenance';
import LatestComments from './widgets/latestcomments';
import NewScreenshots from './widgets/newscreenshots';
import Discord from './widgets/discord';

export default {
  page: {
    title: "Home",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    ffxivNews,
    ffxivMaintenance,
    LatestComments,
    NewScreenshots,
    Discord
  },
  data: function() {
    return {
      result: '',
      env: process.env.NODE_ENV,
      language: navigator.language
    };
  }
};
//console.log = 'Cookies: ' + this.cookies.keys()
</script>

<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
            <h4 class="font-size-18">{{ $t('messages.welcome') }}</h4>
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item active">{{ $t('messages.subtitle') }}</li>
              <li class="breadcrumb-item active">Runtime Enviroment: {{ env }}</li>
              <li class="breadcrumb-item active">Browser Language: {{ language }}</li>
            </ol>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <form action="{{ url('search" method="post" class="form-group">
          <div class="form-row">
            <div class="col-xl-2">
              <select name="search_type" class="form-control">
                <option value="all">All</option>
                <option value="character">Characters</option>
                <option value="freecompany">Free Company</option>
                <option value="linkshell">Linkshell</option>
                <option value="pvp">PVP Team</option>
                <option value="content">Content</option>
              </select>
            </div>
            <div class="col">
              <input type="text" name="search_query" id="search_query" class="form-control" placeholder="Enter something to search" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <!-- start card -->
      <div class="col-xl-9">
        <div class="card">
          <div class="card-body">
            <ffxivNews></ffxivNews>
          </div>
        </div>
      </div>
      <!-- end card -->
      <div class="col-xl-3">
        <div class="card">
          <div class="card-body">
            <ffxivMaintenance></ffxivMaintenance>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Discord</h4>
            <Discord></Discord>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-9">
        <div class="card">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">{{ $t('messages.latestcomments') }}</h4>
              <LatestComments></LatestComments>
          </div>
        </div>
      </div>
      <div class="col-xl-3">
        <div class="card">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">{{ $t('main.newscreenshots') }}</h4>
            <NewScreenshots></NewScreenshots>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>