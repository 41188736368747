<script>
export default { //TODO: Get Comments from API Endpoint
    
}
</script>
<template>
    <div>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">{{ $t('messages.name') }}</th>
                        <th scope="col">{{ $t('comments.date') }}</th>
                        <th scope="col" colspan="2">{{ $t('comments.pageinfo') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">14256</th>
                        <td>
                            <div>
                                <img src="@/assets/images/users/yoship.jpg" alt="" class="avatar-xs rounded-circle mr-2"> Naoki Yoshida
                            </div>
                        </td>
                        <td>15/1/2018</td>
                        <td><span class="badge badge-success">GLaDOS</span></td>
                        <td>
                            <div>
                                <a href="#" class="btn btn-primary btn-sm">{{ $t('comments.view') }}</a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">14257</th>
                        <td>
                            <div>
                                <img src="@/assets/images/users/jamesholden.jpg" alt="" class="avatar-xs rounded-circle mr-2"> James Holden
                            </div>
                        </td>
                        <td>16/1/2019</td>
                        <td><span class="badge badge-warning">GLaDOS</span></td>
                        <td>
                            <div>
                                <a href="#" class="btn btn-primary btn-sm">{{ $t('comments.view') }}</a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">14258</th>
                        <td>
                            <div>
                                <img src="@/assets/images/users/amos.jpg" alt="" class="avatar-xs rounded-circle mr-2"> Amos Burton
                            </div>
                        </td>
                        <td>17/1/2019</td>
                        <td><span class="badge badge-success">GLaDOS</span></td>
                        <td>
                            <div>
                                <a href="#" class="btn btn-primary btn-sm">{{ $t('comments.view') }}</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>