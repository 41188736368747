<script>
import axios from 'axios'; //TODO: Show Error Message if no Data retrieved from API

export default {
  data: function () {
		return {
			ffxivnews: []
		}
	},
	created: async function () {
		await this.fetchFFXIVNewsData()
	},
	methods: {
		fetchFFXIVNewsData: function () {
      const uri = process.env.VUE_APP_XIVAPI + '/ffxiv_news/' + this.$i18n.locale
      this.debugLevel('URI loaded: ' + uri)

			return axios.get(uri).then((response) => {
				this.ffxivnews = response.data
				this.debugLevel(['FFXIV News data successfully loaded: ', this.ffxivnews])
			}).catch((error) => {
				this.debugLevel('FFXIV News data failed to load with error: ' + error)
			})
		},

    debugLevel: function (value, level = 2) {
      const values = [];
        Array.isArray(value) ? value.forEach(element => values.push(element)) : values[0] = value;
          switch (level) {
            case 1:
              if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'development') {
                values.forEach(element => console.log(element));
              }
              break;
            case 2:
              if (process.env.NODE_ENV == 'development') {
                values.forEach(element => console.log(element));
              }
              break;
            default:
              values.forEach(element => console.log(element));
              break;
          }
    },
	},
};
</script>
<template>
<div>
    <div v-for="news in ffxivnews.slice(0, 4)" :key="news.id" class="row no-gutters position-relative">
        <div class="col-md-6 mb-md-0 p-md-4">
            <img :src="news.image" class="w-100">
        </div>
        <div class="col-md-6 position-static p-4 pl-md-0">
            <h5 class="mt-0">{{ news.title }}</h5>
            <p>{{ news.description }}</p>
            <a :href="news.url" target="_blank" class="stretched-link">{{ $t('news.view') }}</a>
        </div>
    </div>
    <a href="/news/ffxiv" class="btn btn-primary align-center float-right">{{ $t('index.more') }}</a>
</div>
</template>