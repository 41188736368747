<script>
import axios from 'axios'; //TODO: Integrate an Refresh Data Button, refresh Data per Interval

export default {
    data: function () {
		return {
			mt: []
		}
	},
	created: async function () {
		await this.fetchFFXIVMaintenanceData()
	},
	methods: {
		fetchFFXIVMaintenanceData: function () {
            const uri = process.env.VUE_APP_XIVAPI + '/ffxiv_maintenance/'
            this.debugLevel('Maintenance Data loaded: ' + uri)

			return axios.get(uri).then((response) => {
				this.mt = response.data
				this.debugLevel(['FFXIV Maintenance data successfully loaded: ', this.mt])
			}).catch((error) => {
				this.debugLevel('FFXIV Maintenance data failed to load with error: ' + error)
			})
		},

    debugLevel: function (value, level = 2) {
      const values = [];
        Array.isArray(value) ? value.forEach(element => values.push(element)) : values[0] = value;
          switch (level) {
            case 1:
              if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'development') {
                values.forEach(element => console.log(element));
              }
              break;
            case 2:
              if (process.env.NODE_ENV == 'development') {
                values.forEach(element => console.log(element));
              }
              break;
            default:
              values.forEach(element => console.log(element));
              break;
          }
    },
	},
}
</script>
<template>
    <div>
        <div class="row">
            <div class="col-5 mb-1">
                {{ $t('index.companion') }}
            </div>
            <div v-if="mt.companion !== null" class="col-7 mb-1">
                <div v-if="mt.companion.current == true" class="col-7 bg-danger text-black rounded">
                    {{ $t('index.mtongoing') }}
                </div>
                <div v-else class="pl-2 bg-info text-black rounded"> <!--- //TODO: Show how long until Maintenance -->
                    {{ $t('index.soon')}} 
                </div>
            </div>
            <div v-else class="col-7 mb-1">
                <div class="bg-success pl-2 rounded">
                    {{ $t('index.nomt') }}
                </div>
            </div>
            
            <div class="col-5 mb-1">
                {{ $t('index.game') }}
            </div>
            <div v-if="mt.game !== null" class="col-7 mb-1">
                <div v-if="mt.game.current == true" class=" pl-2 bg-danger text-black rounded">
                    {{ $t('index.mtongoing') }}
                </div>
                <div v-else class="pl-2 bg-info text-black rounded">
                    {{ $t('index.soon')}}
                </div>
            </div>
            <div v-else class="col-7 mb-1">
                <div class="bg-success pl-2 rounded">
                    {{ $t('index.nomt') }}
                </div>
            </div>
            
            <div class="col-5 mb-1">
                {{ $t('index.lodestone') }}
            </div>
            <div v-if="mt.lodestone !== null" class="col-7 mb-1">
                <div v-if="mt.lodestone.current == true" class="pl-2 bg-danger text-black rounded">
                    {{ $t('index.mtongoing') }}
                </div>
                <div v-else class="pl-2 bg-info text-black rounded">
                    {{ $t('index.soon')}}
                </div>
            </div>
            <div v-else class="col-7 mb-1">
                <div class="bg-success rounded pl-2">
                    {{ $t('index.nomt') }}
                </div>
            </div>
            
            <div class="col-5 mb-1">
                {{ $t('index.mog') }}
            </div>
            <div v-if="mt.mog !== null" class="col-7 mb-1">
                <div v-if="mt.mog.current == true" class="pl-2 bg-danger text-black rounded">
                    {{ $t('index.mtongoing') }}
                </div>
                <div v-else class="pl-2 bg-info text-black rounded">
                    {{ $t('index.soon')}}
                </div>
            </div>
            <div v-else class="col-7 mb-1">
                <div class="pl-2 bg-success rounded">
                    {{ $t('index.nomt') }}
                </div>
            </div>
            
            <div class="col-5">
                {{ $t('index.psn') }}
            </div>
            <div v-if="mt.psn !== null" class="col-7">
                <div v-if="mt.psn.current == true" class="pl-2 bg-danger text-black rounded">
                    {{ $t('index.mtongoing') }}
                </div>
                <div v-else class="pl-2 bg-info text-black rounded">
                    {{ $t('index.soon')}}
                </div>
            </div>
            <div v-else class="col-7">
                <div class="pl-2 bg-success rounded">
                    {{ $t('index.nomt') }}
                </div>
            </div>
        </div>
        <!-- <div class="divider my-4"></div>
        <a href="#" class="btn btn-primary btn-block">{{ $t('index.more') }}</a> -->
    </div>
</template>