<script>
import axios from 'axios'; //TODO: Display an Error Message like "no data avaible" if API doesnt send any data

export default {
    data: function () {
		return {
			discord: []
		}
	},
	created: async function () {
		await this.fetchDiscordData()
	},
	methods: {
		fetchDiscordData: function () {
      const uri = process.env.VUE_APP_XIVAPI + '/discord'
      this.debugLevel('URI loaded: ' + uri)

			return axios.get(uri).then((response) => {
				this.discord = response.data
				this.debugLevel(['Discord data successfully loaded: ', this.discord])
			}).catch((error) => {
				this.debugLevel('Discord data failed to load with error: ' + error)
			})
		},

    debugLevel: function (value, level = 2) {
      const values = [];
        Array.isArray(value) ? value.forEach(element => values.push(element)) : values[0] = value;
          switch (level) {
            case 1:
              if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'development') {
                values.forEach(element => console.log(element));
              }
              break;
            case 2:
              if (process.env.NODE_ENV == 'development') {
                values.forEach(element => console.log(element));
              }
              break;
            default:
              values.forEach(element => console.log(element));
              break;
          }
    },
    hasChannelMembers: function (id) {
      if (this.discord.channelMembers) {
        return Object.prototype.hasOwnProperty.call(this.discord.channelMembers, id)
      }
    },
    },
}
</script>
<template>
    <div>
        <div class="xivdataDiscordWidget" data-simplebar>
            <h5 class="align-center">{{ $t('discord.voicechannel') }} <span class="badge badge-secondary">{{ discord.channel_count }}</span></h5>
            <ul class="list-unstyled">
                <i v-if="!discord.channel_list">{{ $t('discord.no_vc') }}</i>
                <li v-for="voicechannel in discord.channel_list" :key="voicechannel.id">
                    <i class="fas fa-volume"></i>
                    {{ voicechannel.name }}
                    <ul v-if="hasChannelMembers(voicechannel.id)">
                        <li v-for="channelMember in discord.channelMembers[voicechannel.id]" :key="channelMember.id">
                            {{ channelMember.username }} 
                            <i v-if="channelMember.self_mute || channelMember.mute" class="fas fa-microphone-slash"></i>
                            <i v-if="channelMember.self_deaf || channelMember.deaf" class="fas fa-volume-slash"></i>
                        </li>
                    </ul>
                </li>
            </ul>
            <h5>{{ $t('discord.membersonline') }} <span class="badge badge-secondary">{{ discord.member_count }}</span></h5>
            <div class="discordWidgetUsers">
                <ul class="list-unstyled">
                    <li class="widget-member" v-for="member in discord.member_list" :key="member.id">
                        <div class="widget-member-avatar align-top">
                            <span class="widget-member-status noAvatar" :class="'widget-member-status-' + member.status"></span>
                        </div>
                        <div class="widget-member-name align-baseline">
                            {{ member.username }}
                        </div>
                        <div v-if="member.game" class="widget-member-game align-baseline">
                            {{ member.game.name }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="divider"></div>
        <a :href="discord.instant_invite" target="_blank" class="btn btn-primary btn-block"><b>{{ $t('discord.join', { servername: discord.server_name }) }}</b></a>
    </div>
</template>