<script>
export default {
    
}
</script>
<template>
    <div>
        <div class="wid-peity mb-4">
            <div class="row">
                <div class="col-md-6">
                    <div>
                        <p class="text-muted">Demo Screenshot 01</p>
                        <h5 class="mb-4"><i class="fas fa-eye"></i> 1.542</h5>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-4">
                        <img src="@/assets/images/demo01.png" alt="" class="img-fluid rounded" />
                    </div>
                </div>
            </div>
        </div>
        
        <div class="wid-peity mb-4">
            <div class="row">
                <div class="col-md-6">
                    <div>
                        <p class="text-muted">Demo Screenshot 02</p>
                        <h5 class="mb-4"><i class="fas fa-eye"></i> 1.823</h5>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-4">
                        <img src="@/assets/images/demo02.png" alt="" class="img-fluid rounded" />
                    </div>
                </div>
            </div>
        </div>

        <div class="wid-peity mb-4">
            <div class="row">
                <div class="col-md-6">
                    <div>
                        <p class="text-muted">Demo Screenshot 03</p>
                        <h5 class="mb-4"><i class="fas fa-eye"></i> 2.934</h5>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-4">
                        <img src="@/assets/images/demo03.png" alt="" class="img-fluid rounded" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>